
/**
 * @name: index
 * @author: lili
 * @date: 2023-05-31 14:21
 * @description：营销管理-banner
 * @update: 2023-05-31 14:21
 */
import {Component, Vue} from "vue-property-decorator";
import config from "@/config";
import {Message} from "element-ui";
import {
  createBannerApi, getBannerDetailApi,
  getBannerRemoveApi, modifyBannerApi,
  modifyBannerStatusApi,
  queryBannerListApi,
  querySkipContentListApi
} from "@/apis/marketingManage/banner";
import {IBanner} from "@/apis/marketingManage/banner/types";
import {ICrudOption} from "@/types/m-ui-crud";


@Component({})
export default class UserIndex extends Vue {

  queryForm: any = {
    page: 1,
    limit: 10
  }
  //跳转类型: 1=商品 2=旅游 3=赛事 4=1v1课程 5=网红 6=视频课程
  jumpTypes=[
    {
      label:"商品",
      value:1
    },
    {
      label:"旅游",
      value:2
    },
    {
      label:"赛事",
      value:3
    },
    {
      label:"1v1课程",
      value:4
    },
    {
      label:"网红",
      value:5
    },
    {
      label:"视频课程",
      value:6
    }
  ]

  skipContentList = []

  tableData: any[] = []

  // 新增修改表单
  modelForm: IBanner = {
    status: 1
  }

  /***
   * crudOption
   * 页面配置对象
   */
  crudOption: ICrudOption = {
    searchBox: false,
    // menu: false,
    editBtn:true,
    delBtn:true,
    column: [
      {
        label: "ID",
        prop: "id",
        align: 'center',
        width: 200,
        addHide:true,
        editHide:true,
      },
      {
        label: "图片",
        prop: "bannerPic",
        type: 'image',
        align: 'center',
        search: false,
        addSlot: true,
        editSlot: true,
        imgPrefix: config.downloadUrl,
        rules: [
          {required: true, message: '请上传轮播图片', trigger: 'blur'},
        ],
        span: 24,
      },
      {
        label: "跳转链接",
        prop: "jumpTypes",
        align: 'center',
        type: 'select',
        dicData: this.jumpTypes,
        span: 12,
        // @ts-ignore
        onchange: this.onChangeJumpTypes
      },
      {
        label: "跳转内容",
        prop: "contentName",
        align: 'center',
        addHide:true,
        editHide:true,

      },
      {
        label: "跳转内容",
        prop: "jumpId",
        align: 'center',
        hide: true,
        span: 12,
        type: 'select',
        dicData:[],
        // @ts-ignore
        onchange: this.onChangeJumpId
      },
      {
        label: "状态",
        prop: "status",
        align: 'center',
        width: 150,
        slot: true,
        addHide:true,
        editHide:true,
        value: 2,
        type: "switch",
        dicData: [{label: '禁用', value: 2}, {label: '启用', value: 1}],
      },
      {
        label: "创建时间",
        prop: "addTime",
        width: 150,
        addHide:true,
        editHide:true,
      },
      {
        label: "更新时间",
        prop: "addTime",
        width: 150,
        addHide:true,
        editHide:true,
      },
      {
        label: "排序",
        prop: "sort",
        align: 'center',
        span: 12,
        rules: [{
          required: true,
          validator: (rule: any, value: any, callback: any) => {
            if (!value) {
              return callback(new Error("请输入排序"));
            } else if (!/^[1-9]\d{0,4}$/.test(value)) {
              callback(new Error("请输入排序为正整数，最大长度4位数!"));
            } else {
              callback();
            }
          }, trigger: 'blur'
        },
        ],
      },
    ]
  }

  /**
   * 获取数据
   */
  getList() {
    queryBannerListApi().then(e => {
      this.tableData = e;
    })
  }

  /**
   * 修改状态提交
   */
  handleStatus(row: any, val: number) {
    modifyBannerStatusApi({id: row.id, status: val}).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList()
      }
    })
  }

  /**
   * 上传前检验
   * @param file
   */
  beforeUpload(file: File) {
    if (file.type != "image/png" && file.type != "image/jpg" && file.type != "image/jpeg") {
      Message.error("请选择正确格式")
      return false
    }
  }

  submitRemove(form: any, done: any, loading: any) {
    getBannerRemoveApi(form.id).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
        this.getList();
        loading();
        done();
      }
    }).finally(() => {
      loading();
    })
  }
  /**
   * 切换跳转类型 加载跳转内容
   */
  onChangeJumpTypes(){
    this.modelForm.jumpId = ""
    querySkipContentListApi(this.modelForm.jumpTypes as any).then(e=>{
     let list=e as [];
      this.skipContentList=list.map((e:any)=>{
        return{
          label:e.skipName,
          value:e.id
        }
      }) as any

      // @ts-ignore
      this.$refs.crudRef.updateFormColumn('jumpId', {
        dicData: this.skipContentList,
      })
    })
  }

  /**
   * 触发跳转内容刷新
   */
  onChangeJumpId(){
    this.$forceUpdate();
  }



  /**
   * 新增提交
   * @param  form: any, done: any, loading: any
   */
  submitSave(form: any, done: any, loading: any) {
    createBannerApi(form).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList();
        loading();
        done();
      }
    }).finally(() => {
      loading();
    })
  }

  /**
   * 修改提交
   * @param  form: any, done: any, loading: any
   */
  submitEdit(form: any, done: any, loading: any) {
    modifyBannerApi(form).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList();
        loading();
        done();
      }
    }).finally(() => {
      loading();
    })
  }

  /**
   * 修改前
   * @param  item: any, index: number
   */
  handleShowEidt(item: any, index: number) {
    getBannerDetailApi(item.id).then(e => {

      // @ts-ignore
      this.$refs.crudRef.rowEdit(e, index);
      if (e.jumpTypes){
        this.modelForm.jumpTypes=e.jumpTypes;
        this.onChangeJumpTypes();
      }
    })
  }

  handleRemove (row: any) {
    getBannerRemoveApi(row.id).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
        this.getList();
      }
    })
  }


  created() {
    this.getList();
  }
}
